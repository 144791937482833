
import { PatrolReportWeekStatistic } from '../../types/inspection'
import { Component, Vue } from 'vue-property-decorator'
import { DicInfo, DicList } from '../../types/common'
import Echarts from 'echarts'

@Component
export default class WeekStatistical extends Vue {
  searchForm = {
    patrolType: '',
    startDate: '',
    endDate: ''
  }

  activeNames1 = ['1', '2', '3', '4']
  activeNames2 = ['3-1', '3-2', '3-3', '3-4']
  _chart1: any = null
  _chart2: any = null

  dateArr: any = ''

  dicList: DicInfo[] = []

  issueList: any = []

  tableData: any = []

  patrolReportWeekStatistic: any = {
    analysis: {
      hw: [],
      sz: [],
      wy: [],
      yl: []
    },
    totality: {
      list: [],
      map: {}
    },
    compare: {
      beforeDatas: [],
      currentDatas: [],
      perDatas: [],
      projectTypeNames: []
    }
  }

  created () {
    this.dateChange(null)
    this.loadDict()
    this.loadData()
    this.loadUnCompleteedData()
    window.addEventListener('resize', this.eChartResize)
  }

  beforeDestroy () {
    this._chart1 && this._chart1.dispose()
    this._chart2 && this._chart2.dispose()
    window.removeEventListener('resize', this.eChartResize)
  }

  eChartResize () {
    this._chart1 && this._chart1.resize()
    this._chart2 && this._chart2.resize()
  }

  getIssueFileUrl (files: any) {
    return files.map((item: any) => {
      return item.filePrefix + item.fileUrl
    })
  }

  getSummaries (params: any) {
    const issueAmount = params.data.reduce((prev: any, next: any) => {
      return prev + parseInt(next.issueAmount)
    }, 0)
    const issueAmountCompeleted = params.data.reduce((prev: any, next: any) => {
      return prev + parseInt(next.issueAmountCompeleted)
    }, 0)
    return ['小计', issueAmount, '', issueAmountCompeleted, '']
  }

  // 巡查类别
  loadDict () {
    this.$axios.get<DicList>(this.$apis.common.selectDicByList, { dicType: 'patrolType' }).then(res => {
      this.dicList = res.patrolType || []
    })
  }

  loadData () {
    this.$axios.get<PatrolReportWeekStatistic>(this.$apis.inspectionReport.selectWeekStatistic, this.searchForm).then(res => {
      this.patrolReportWeekStatistic = res
      this.$nextTick(() => {
        this.initChart1(res.totality)
        this.initChart2(res.compare)
      })
    })
  }

  loadUnCompleteedData () {
    this.$axios.get(this.$apis.inspectionReport.selectUnCompeletedIssuePage, this.searchForm).then(res => {
      this.issueList = res.list
    })
  }

  onSearch () {
    this.loadData()
    this.loadUnCompleteedData()
  }

  initChart1 (data: any) {
    const items: any = []
    data.list.forEach((item: any) => {
      items.push({ name: item.projectTypeName, value: data.map[item.projectTypeCode] })
    })
    const count = items.reduce((prev: any, next: any) => {
      return prev + next.value
    }, 0)
    this._chart1 = Echarts.init(this.$refs.chart1 as any)
    const option = {
      tooltip: {
        trigger: 'item'
      },
      title: {
        text: `{a|问题总数\n}{b|${count}}`,
        left: '21%',
        top: '35%',
        textStyle: {
          lineHeight: 36,
          rich: {
            a: {
              fontSize: 14,
              color: '#999999',
              align: 'center'
            },
            b: {
              fontSize: 24,
              color: 'rgba(0, 0, 0, 0.85)',
              align: 'center',
              padding: [20, 0, 0, 0]
            }
          }
        }
      },
      color: ['#47A2FF', '#1AD1B0', '#FBD44C', '#9760E5'],
      legend: {
        left: '45%',
        top: 60,
        orient: 'vertical',
        icon: 'circle',
        itemGap: 16,
        itemWidth: 8,
        itemHeight: 8,
        formatter (res: any) {
          const data = items.find((item: any) => item.name === res)
          let percent = '0'
          if (count !== 0) {
            percent = (data.value / count * 100).toFixed(2)
          }
          return `{a|${res}}{b| | ${percent}%}{c| ${data.value}}`
        },
        textStyle: {
          rich: {
            a: {
              fontSize: 14,
              color: '#666666',
              width: 100
            },
            b: {
              fontSize: 14,
              color: '#999999',
              width: 60
            },
            c: {
              fontSize: 14,
              color: '#333333',
              width: 30,
              align: 'right'
            }
          }
        }
      },
      series: [
        {
          name: '板块',
          type: 'pie',
          radius: ['50%', '70%'],
          left: '-50%',
          avoidLabelOverlap: false,
          itemStyle: {
            borderRadius: 10,
            borderColor: '#fff',
            borderWidth: 2
          },
          label: {
            show: false,
            position: 'center'
          },
          labelLine: {
            show: false
          },
          data: items
        }
      ]
    }
    this._chart1.clear()
    this._chart1.setOption(option)
  }

  initChart2 (data: any) {
    this._chart2 = Echarts.init(this.$refs.chart2 as any)
    const option = {
      tooltip: {
        trigger: 'axis',
        formatter: function (res: any) {
          console.log('resresres', res)
          return `${res[0].name}<br>
                  ${res[0].marker}${res[0].seriesName} ${res[0].value}<br>
                  ${res[1].marker}${res[1].seriesName} ${res[1].value}<br>
                  ${res[2].marker}${res[2].seriesName} ${res[2].value ? res[2].value + '%' : ''}`
        }
      },
      color: ['#FFC117', '#5B8FF9', '#36CBCB'],
      grid: {
        top: 40,
        bottom: 30,
        right: 50,
        left: 50
      },
      legend: {
        show: true,
        itemWidth: 20,
        itemHeight: 1,
        left: 0
      },
      xAxis: [
        {
          type: 'category',
          data: data.projectTypeNames,
          axisTick: {
            show: false
          },
          axisLine: {
            lineStyle: {
              color: 'rgba(0, 0, 0, .45)'
            }
          },
          axisLabel: {
            color: 'rgba(0, 0, 0, .45)',
            fontSize: 12
          }
        }
      ],
      yAxis: [
        {
          type: 'value',
          min: 0,
          axisLabel: {
            formatter: '{value}',
            color: 'rgba(0, 0, 0, .45)',
            fontSize: 12
          },
          axisTick: {
            show: false
          },
          axisLine: {
            show: false
          }
        },
        {
          type: 'value',
          axisLabel: {
            formatter: '{value}%'
          },
          axisTick: {
            show: false
          },
          axisLine: {
            show: false
          }
        }
      ],
      series: [
        {
          name: '本周',
          type: 'bar',
          barWidth: 16,
          data: data.currentDatas
        },
        {
          name: '上周',
          type: 'bar',
          barWidth: 16,
          data: data.beforeDatas
        },
        {
          name: '环比',
          type: 'line',
          smooth: true,
          yAxisIndex: 1,
          data: data.perDatas
        }
      ]
    }
    this._chart2.clear()
    this._chart2.setOption(option)
  }

  dateChange (date: any) {
    if (!date) {
      date = this.$dayjs().format('YYYY-MM-DD')
      this.$nextTick(() => {
        this.dateArr = this.$dayjs().format('YYYY-MM-DD')
      })
    }
    this.searchForm.startDate = this.$dayjs(date).subtract(1, 'day').startOf('week').add(1, 'day').format('YYYY-MM-DD')
    this.searchForm.endDate = this.$dayjs(date).subtract(1, 'day').endOf('week').add(1, 'day').format('YYYY-MM-DD')
  }
}
